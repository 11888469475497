.bg {

    &-image {

        &-a001 {
            background-image: url("/assets/images/bg/a001.jpg");
        }

    }
}
