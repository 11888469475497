@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
@import "~sweetalert2/dist/sweetalert2.css";
@import "~animate.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
 * 圖片顯示比例
 */
/* 1:1 Aspect Ratio */
.square-img {
  padding-top: 100%;
  width: 100%;
  position: relative;
  /* If you want text inside of it */
}
.square-img a, .square-img span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square-img a img, .square-img span img {
  width: 100%;
}

.square-img-in {
  padding-top: 100%;
  width: 100%;
  position: relative;
  /* If you want text inside of it */
}
.square-img-in a, .square-img-in span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square-img-in a img, .square-img-in span img {
  width: 100%;
}
.square-img-in a img, .square-img-in span img {
  width: auto !important;
  height: 100% !important;
}

/* 2:1 Aspect Ratio */
.rectangle-img-2-1 {
  padding-top: 50%;
  width: 100%;
  position: relative;
  /* If you want text inside of it */
}
.rectangle-img-2-1 a, .rectangle-img-2-1 span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangle-img-2-1 a img, .rectangle-img-2-1 span img {
  width: 100%;
}

/* 3:2 Aspect Ratio */
.rectangle-img-3-2 {
  padding-top: 66%;
  width: 100%;
  position: relative;
  /* If you want text inside of it */
}
.rectangle-img-3-2 a, .rectangle-img-3-2 span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangle-img-3-2 a img, .rectangle-img-3-2 span img {
  width: 100%;
}

/* 16:19 Aspect Ratio */
.rectangle-img-16-9 {
  padding-top: 56.25%;
  width: 100%;
  position: relative;
  /* If you want text inside of it */
}
.rectangle-img-16-9 a, .rectangle-img-16-9 span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangle-img-16-9 a img, .rectangle-img-16-9 span img {
  width: 100%;
}

/* 16:10 Aspect Ratio */
.rectangle-img-16-10 {
  padding-top: 56.25%;
  width: 100%;
  position: relative;
  /* If you want text inside of it */
}
.rectangle-img-16-10 a, .rectangle-img-16-10 span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangle-img-16-10 a img, .rectangle-img-16-10 span img {
  width: 100%;
}

.btn {
  @apply inline-block font-normal text-center px-3 py-2 leading-normal text-base rounded cursor-pointer;
}
.btn-primary {
  @apply text-white bg-blue-600;
}
.btn-primary:hover {
  @apply text-white bg-blue-700;
}
.btn-secondary {
  @apply text-white bg-gray-600;
}
.btn-secondary:hover {
  @apply text-white bg-gray-700;
}
.btn-success {
  @apply text-white bg-green-600;
}
.btn-success:hover {
  @apply text-white bg-green-700;
}
.btn-danger {
  @apply text-white bg-red-600;
}
.btn-danger:hover {
  @apply text-white bg-red-700;
}
.btn-warning {
  @apply text-black bg-yellow-500;
}
.btn-warning:hover {
  @apply text-black bg-yellow-600;
}
.btn-info {
  @apply text-white bg-teal-500;
}
.btn-info:hover {
  @apply text-white bg-teal-600;
}
.btn-light {
  @apply text-black bg-gray-200;
}
.btn-light:hover {
  @apply text-black bg-gray-300;
}
.btn-dark {
  @apply text-white bg-gray-900;
}
.btn-dark:hover {
  @apply text-white bg-black;
}
.btn-link {
  @apply text-blue-500;
}
.btn-link:hover {
  @apply text-blue-800;
}

.bg-image-a001 {
  background-image: url("/assets/images/bg/a001.jpg");
}

#nav {
  background-color: #FFF;
}

.base-color {
  color: #990011FF;
}

.home-section-title span:before, .home-section-title span:after {
  display: block;
  content: " ";
  position: absolute;
  width: 35%;
  height: 1px;
  background-color: #555;
  top: 50%;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.home-section-title span:before {
  left: -40%;
}
.home-section-title span:after {
  right: -40%;
}

.celebrity-owl .owl-nav {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  margin-top: 0 !important;
}
.celebrity-owl .owl-nav .owl-prev, .celebrity-owl .owl-nav .owl-next {
  background: 0 0;
  border: none;
  padding: 0 !important;
  font: inherit;
  position: absolute;
  width: 25px;
  color: #fff !important;
  border-radius: 0 !important;
}
.celebrity-owl .owl-nav .owl-prev span, .celebrity-owl .owl-nav .owl-next span {
  color: transparent;
}
.celebrity-owl .owl-nav .owl-prev:hover, .celebrity-owl .owl-nav .owl-next:hover {
  background-color: transparent !important;
}
.celebrity-owl .owl-nav .owl-prev {
  left: -2.25rem;
}
.celebrity-owl .owl-nav .owl-prev:before {
  font-size: 2em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  color: gray;
}
.celebrity-owl .owl-nav .owl-next {
  right: -2.25rem;
}
.celebrity-owl .owl-nav .owl-next:before {
  font-size: 2em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  color: gray;
}

.doctor-owl .owl-item:nth-child(odd) {
  @apply bg-gradient-to-b from-blueGray-700 to-gray-900;
}
.doctor-owl .owl-item:nth-child(even) {
  @apply bg-gradient-to-t from-blueGray-700 to-gray-900;
}

.doctor_info ul, .doctor_info ol {
  padding: 0;
  margin: 0;
}
.doctor_info li, .doctor_info p {
  margin: 0 0 0.5rem;
  padding-left: 1rem;
}
.doctor_info li::before, .doctor_info p::before {
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  min-width: 1.2rem;
  font-size: 0.8rem;
  content: "";
}

.iframe-rwd {
  position: relative;
  padding-bottom: 50%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.iframe-rwd iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 639.98px) {
  .home-section-title span:before, .home-section-title span:after {
    width: 20%;
  }
  .home-section-title span:before {
    left: -20%;
  }
  .home-section-title span:after {
    right: -20%;
  }
}