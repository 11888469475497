/*
 * 圖片顯示比例
 */
@mixin image-aspect-ratio {
    //background-color: red;
    width: 100%;
    // padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */

    a,span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }
    }
}


/* 1:1 Aspect Ratio */
.square-img {
    padding-top: 100%;
    @include image-aspect-ratio;
}

.square-img-in {
    padding-top: 100%;
    @include image-aspect-ratio;

    a, span {
        img {
            width: auto !important;
            height: 100% !important;
        }
    }
}

/* 2:1 Aspect Ratio */
.rectangle-img-2-1 {
    padding-top: 50%;
    @include image-aspect-ratio;
}

/* 3:2 Aspect Ratio */
.rectangle-img-3-2 {
    padding-top: 66%;
    @include image-aspect-ratio;
}


/* 16:19 Aspect Ratio */
.rectangle-img-16-9 {
    padding-top: 56.25%;
    @include image-aspect-ratio;
}

/* 16:10 Aspect Ratio */
.rectangle-img-16-10 {
    padding-top: 56.25%;
    @include image-aspect-ratio;
}
